import React, { Component } from 'react';
import fetch from 'isomorphic-fetch';
import { StaticQuery, graphql } from 'gatsby';
import './SearchFilter.scss';
import GatsbyLink from '../GatsbyLink';
import { getUrlVars } from '../../utils/helpers';
import { decodeEntities } from '../../utils/htmlParse';
import Loading from '../Loading';

const ListItem = ({ item }) => {
  const {
    url,
    title,
    subtype
  } = item;
  const slug = url.replace(process.env.GATSBY_WP_ADDRESS, '')
  if (slug.includes('gatsby')) return null
  return (
    <div className="list-item">
      <div className="title">
        <GatsbyLink
          to={slug}
        >
          {decodeEntities(title)}
        </GatsbyLink>
      </div>
      {subtype && (
        <div className="description">
          {decodeEntities(subtype.replace('_', ' '))}
        </div>
      )}
    </div>
  )
}

class ThisSiteSearch extends Component {
    constructor(props) {
        const pageAttr = getUrlVars()
        super(props);
        this.state = {
          loading: false,
          filterResults: [],
          error: null,
          search: '',
          totalResultCount: 0,
          searchMessage: null,
          page: parseInt(pageAttr.page) > 1 ? parseInt(pageAttr.page) : 1
        }
    }

    componentDidMount() {
      const pageAttr = getUrlVars()
      this.setState({
        search: pageAttr.search || ''
      }, () => {
        this.getSearch();
      })
    }

    handleSearchSubmit = ( e ) => {
        e.preventDefault()
        this.getSearch();
    }

    handleSearchBarUpdate = (e) => {
        this.setState({
          search: e.target.value,
        })
    }

    getSearch = () => {
        const { search, page } = this.state
        if (!search) return null
        const productsUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/search/?search=${search}&per_page=50&page=${page}` //Removed pagintaion to keep it simple. Use page=1... if it needs to be added back in
        this.setState({ loading: true });
        fetch(productsUrl)
          .then(async (res) => {
            const total = res.headers.get( 'x-wp-total' )
            const totalpages = res.headers.get( 'x-wp-totalpages' )
            const data = await res.json();
            this.setState({ 
              loading: false, 
              filterResults: data, 
              totalResultCount: total, 
              totalResultPages: totalpages,
              searchMessage: `${total} search results`
            });
          })
          .catch(error => {
            console.log('error:',error)
            this.setState({ loading: false, error });
          })
    }

    handleNavigationWithContext = (e, link) => {
      e.preventDefault();
      this.setState({
        page: link,
      }, () => {
        this.scrollIntoView(e, 'layout')
        this.getSearch();
      });
    };

    scrollIntoView = (event, containerId) => {
      if (typeof window !== 'undefined') {
        event.preventDefault();
        containerId = containerId.replace('#','')
        const results = document.getElementById(containerId) ? document.getElementById(containerId) : null;
        results && results.scrollIntoView({ block: 'start',  behavior: 'smooth' });
      }
    }

    render() {
        const { filterResults, search, loading, error, searchMessage, page, totalResultCount, totalResultPages } = this.state

        const paged = page;
        const itemCount = totalResultCount;
        const perPage = 50; //Match to wp api
        const numberOfPages = totalResultPages;
        const nextPage = paged ? ( parseInt(paged) + 1 ) : 2;
        const prevPage = paged ? parseInt(paged) - 1 : null;
        const range = paged ? perPage * paged : perPage;
        var pages = [];
        for (var i = 0; i < numberOfPages; i++) {
              pages.push(i+1);
        }
        const showRangeMin = Number(paged - 5);
        const showRangeMax = Number(paged + 5);

        return (
            <div className="site-search-filter">
                <div className="wrapper">

                    <form onSubmit={event => this.handleSearchSubmit(event)}>
                      <div className={`search-bar${search ? ' active' : ''}`}>
                        <div className="input-container">
                            <input
                            onChange={event => this.handleSearchBarUpdate(event)}
                            id="searchbar"
                            type="text"
                            name="search"
                            value={search}
                            autoComplete='off'
                            />
                            <label htmlFor="searchbar" className="placeholder">Site search</label>
                            <button className="search-icon" />
                        </div>
                      </div>
                    </form>

                    {loading &&
                      <div className="loading-container"><Loading text="Loading..." /></div>
                    }

                    {!loading && error && (
                      <div style={{padding: '40px 0px'}}>
                        <div>
                          Error: Please try again later.
                        </div>
                      </div>
                    )}

                    {filterResults?.length > 0 ? <p>Page {paged ? paged : 1} of {numberOfPages} - Total results {itemCount}</p> : <p>O results</p>}
                    
                    {filterResults?.length > 0 ? <div className="search-list">
                      {filterResults?.map((page, index) => 
                        <ListItem
                          key={index}
                          item={page}
                          index={index}
                        />
                      )}
                    </div>:
                    <div className="space"/>
                    }
                </div>

                <nav className="pagination" role="navigation">
                  <div className="navbar navbar-menu">
                    {paged != 1 &&
                      <GatsbyLink 
                        onClick={(event) => this.handleNavigationWithContext(event, prevPage)}
                        className="prev" 
                      />
                    }

                    {showRangeMin > 0 &&
                      <>
                        <GatsbyLink 
                          onClick={(event) => this.handleNavigationWithContext(event, parseInt(1))}
                        >1</GatsbyLink>
                        <span>...</span>
                      </>
                    } 

                    {
                      pages.map((key, index) => {
                        const isActive =  parseInt(paged) === key;
                        if (key > showRangeMin && key < showRangeMax) {
                          return (
                            <GatsbyLink 
                              key={index} 
                              className={isActive ? 'active' : '' }
                              onClick={(event) => this.handleNavigationWithContext(event, parseInt(key))}
                            >{key}</GatsbyLink>
                          )
                        }
                      })
                    }

                    {showRangeMax < numberOfPages &&
                      <>
                        <span>...</span>
                        <GatsbyLink 
                            onClick={(event) => this.handleNavigationWithContext(event, parseInt(numberOfPages))}
                        >{numberOfPages}</GatsbyLink>
                      </>
                    }

                    {paged != numberOfPages && numberOfPages > 1 &&
                      <GatsbyLink 
                        onClick={(event) => this.handleNavigationWithContext(event, nextPage)}
                        className="next" 
                      />
                    }
                  </div>
                </nav>
            </div>
        );
    }
}

export const SearchFilter = (props) => {
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => <ThisSiteSearch {...props} data={data} />}
      />
    );
  }