import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import { SearchFilter } from '../components/SiteSearch/SearchFilter';
import './search.scss';

export default class Search extends React.Component {
    render() {
        const {
            data,
            location
        } = this.props;
        const {
            page,
            site: { siteMetadata: { siteTitle } }
        } = data;
        const {
            pageTitle,
            yoast
        } = page;
        return (
            <Layout location={location} pageTitle={pageTitle} className="search-page-template">
                <SEO
                    title={yoast.metaTitle || `${pageTitle} | ${siteTitle}`}
                    desc={yoast.metaDescription}
                />
                <SearchFilter location={location} />
            </Layout>
        );
    }
}

export const pageQuery = graphql`
  query SiteSearchPageQuery($id: String!) {
    site {
      siteMetadata {
        siteTitle: title
      }
    }
    page: wordpressPage(id: { eq: $id }) {
      pageId: wordpress_id
      pageTitle: title
      yoast {
        metaTitle: title,
        metaDescription: metadesc
      }
    }
  }
`